var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "barang-modal",
      "size": "xl",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Pilih Barang")])];
      },
      proxy: true
    }])
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('h5', [_vm._v("Barang dipilih (" + _vm._s(_vm.selectedBarangs.length) + ").")]), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('Filter')
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "filter": _vm.filter,
      "items": _vm.localBarangs,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedBarangs,
            callback: function ($$v) {
              _vm.selectedBarangs = $$v;
            },
            expression: "selectedBarangs"
          }
        })];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_dasar)))])];
      }
    }, {
      key: "cell(harga_dasar_baru)",
      fn: function (_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [item.isChangePrice ? _c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahHDB(index);
            }
          },
          model: {
            value: item.harga_dasar_baru,
            callback: function ($$v) {
              _vm.$set(item, "harga_dasar_baru", $$v);
            },
            expression: "item.harga_dasar_baru"
          }
        }) : _c('strong', [_vm._v(_vm._s(item.harga_dasar))])];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahHB(index);
            }
          },
          model: {
            value: item.harga_beli,
            callback: function ($$v) {
              _vm.$set(item, "harga_beli", $$v);
            },
            expression: "item.harga_beli"
          }
        })];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahDiskon(index);
            }
          },
          model: {
            value: item.diskon,
            callback: function ($$v) {
              _vm.$set(item, "diskon", $$v);
            },
            expression: "item.diskon"
          }
        })];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '') + " ")];
      }
    }, {
      key: "cell(isChangePrice)",
      fn: function (_ref8) {
        var item = _ref8.item,
          index = _ref8.index;
        return [_c('b-form-checkbox', {
          on: {
            "change": function ($event) {
              return _vm.onChangePrice(index);
            }
          },
          model: {
            value: item.isChangePrice,
            callback: function ($$v) {
              _vm.$set(item, "isChangePrice", $$v);
            },
            expression: "item.isChangePrice"
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }